// React
import React, { useState, useEffect } from 'react';
import '../App.scss';

// HTML React Parser
import ReactHtmlParser from 'react-html-parser';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import { CheckLg, XLg } from 'react-bootstrap-icons';

import DiffFile from '../diff.tsv';
import Button from 'react-bootstrap/esm/Button';

export default function ShowDiff() {

    const [data, setData] = useState([]);
    const [header, setHeader] = useState([]);
    const [wordLevel, setWordLevel] = useState(false);

    // Handle file upload
    const [file, setFile] = useState('');
    const [fileError, setFileError] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
      document.title = "Show differences";
    }, []);

    // Get data from diff.tsv file, where source and reference are separated by a tab
    useEffect(() => {
      if (text) {
        const rows = text.split('\n');
        var d = [];
        var h = [];
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i].split('\t');
          if (row.length > 1) {
            if (i === 0) {
              h = []
              h[0] = "ID"
              for (let j = 1; j < row.length + 1; j++) {
                h[j] = row[j-1];
              }
              if (row.length < 2) {
                setFileError('File is not in the correct format');
              }
              setHeader(h);
            }
            else {
              const diff = diffline(row[0], row[1]);
              d[i] = [row[0], diff]
              for (let j = 2; j < row.length; j++) {
                d[i][j] = row[j];
              }
            }
          }
        }
        setData(d);
      } else {
        fetch(DiffFile)
        .then(response => response.text())
        .then(text => {
            const rows = text.split('\n');
            var d = [];
            var h = [];
            for (let i = 0; i < rows.length; i++) {
              const row = rows[i].split('\t');
              if (row.length > 1) {
                if (i === 0) {
                  h = []
                  h[0] = "ID"
                  for (let j = 1; j < row.length + 1; j++) {
                    h[j] = row[j-1];
                  }
                  setHeader(h);
                }
                else {
                  const diff = diffline(row[0], row[1]);
                  d[i] = [row[0], diff]
                  for (let j = 2; j < row.length; j++) {
                    d[i][j] = row[j];
                  }
                }
              }
            }
            setData(d);
        });
      }
    }, [DiffFile, wordLevel, text]);


    // Diff method
    function diffline(line1, line2) {
        var diff = "";
        if (wordLevel) {
          diff = computeDiff(line1.split(" "), line2.split(" "));
        } else {
          diff = computeDiff(Array.from(line1), Array.from(line2));
        }
        const diffval = diff.values;
        const diffmask = diff.mask;
  
        let n = diffval.length;
        let pmc = 0;
        let result = '';
        
        if (wordLevel) {
          for (let i = 0; i < n; i++) {
            let mc = diffmask[i];
            let space = false;
            if (mc !== pmc) {
              space = true;
                switch (pmc) {
                    case -1: result += '</del>' + (i === 0 ? '' : ' '); break;
                    case 1: result += '</ins>' + (i === 0 ? '' : ' '); break;
                }
                switch (mc) {
                    case -1: result += (i === 0 ? '' : ' ') + '<del>'; break;
                    case 1: result += (i === 0 ? '' : ' ') + '<ins>'; break;
                }
            }
            result += (i === 0 && !space ? '' : ' ') + diffval[i];
            pmc = mc;
          }
        } else {
          for (let i = 0; i < n; i++) {
              let mc = diffmask[i];
      
              if (mc !== pmc) {
                  switch (pmc) {
                      case -1: result += '</del>'; break;
                      case 1: result += '</ins>'; break;
                  }
                  switch (mc) {
                      case -1: result += '<del>'; break;
                      case 1: result += '<ins>'; break;
                  }
              }
              result += diffval[i];
              pmc = mc;
          }
      
          switch (pmc) {
              case -1: result += '</del>'; break;
              case 1: result += '</ins>'; break;
          }
        }
    
        return result;
    }
    
    function computeDiff(from, to) {
        let diffValues = [];
        let diffMask = [];
    
        let dm = [];
        let n1 = from.length;
        let n2 = to.length;
    
        for (let i = -1; i < n1; i++) {
            dm[i] = [];
            dm[i][-1] = 0;
        }
        for (let j = -1; j < n2; j++) {
            dm[-1][j] = 0;
        }
    
        for (let i = 0; i < n1; i++) {
            for (let j = 0; j < n2; j++) {
                if (from[i] === to[j]) {
                    let ad = dm[i - 1][j - 1];
                    dm[i][j] = ad + 1;
                } else {
                    let a1 = dm[i - 1][j];
                    let a2 = dm[i][j - 1];
                    dm[i][j] = Math.max(a1, a2);
                }
            }
        }
    
        let i = n1 - 1;
        let j = n2 - 1;
    
        while (i > -1 || j > -1) {
            if (j > -1) {
                if (dm[i][j - 1] === dm[i][j]) {
                    diffValues.push(to[j]);
                    diffMask.push(1);
                    j--;
                    continue;
                }
            }
    
            if (i > -1) {
                if (dm[i - 1][j] === dm[i][j]) {
                    diffValues.push(from[i]);
                    diffMask.push(-1);
                    i--;
                    continue;
                }
            }
    
            diffValues.push(from[i]);
            diffMask.push(0);
            i--;
            j--;
        }
    
        diffValues = diffValues.reverse();
        diffMask = diffMask.reverse();
    
        return { values: diffValues, mask: diffMask };
    }

    const onFileUpload = (event) => {
        const file1 = event.target.files[1];
        setFile(file1);
        if (event.target.files[0]) {
          const reader = new FileReader()
          reader.onload = async (event) => { 
            setText(event.target.result)
          };
          reader.readAsText(event.target.files[0])
        }
    }

    // Handle reset
    const onReset = () => {
      setFile('');
      setText('');
      setFileError('');
      setData([]);
      setHeader([]);
    }

    return (
      <div className="App">
        <Navbar bg='lg' className='pt-5'>
          <Container className='justify-content-md-center'>
            <Navbar.Brand className='header_title '>
              <p className='display-4' style={{fontWeight: "bold"}}>Show differences</p>
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Container fluid className='px-5 mt-2'>
          <Row className='my-4 justify-content-center'>
            <Col>
              <Stack direction="horizontal" gap={3} className='align-items-end justify-content-center w-50 mx-auto'>
                <Form.Group controlId='diff_file' style={{width: "500px"}}>
                  <Form.Label>Upload TSV file with headers</Form.Label>
                  <Form.Control type='file' name='diff_file' onChange={onFileUpload} isInvalid={fileError} value={file}/>
                  <Form.Control.Feedback type="invalid">
                    {fileError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant='secondary' type='reset' className='mx-2' onClick={onReset} >Reset</Button>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex justify-content-center mt-3'>
                <Form.Check
                  type='switch'
                  checked={wordLevel}
                  onChange={() => setWordLevel(!wordLevel)}
                  label="Word-level differences"
                />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-4'>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  { Array.from(header).map((value, index) =>
                    <th key={index} className={ index == 0 || index == 4 ? "text-center" : null}>{value}</th>
                  )}
              </tr>
              </thead>
              <tbody>
                { Object.entries(data).map(([id, values]) =>
                <tr key={id}>
                  <td className="text-center">{id}</td>
                  { Array.from(values).map((value, index) =>
                    <td key={index} className={ index === 3 ? "text-center" : null}>{index == 1 ? ReactHtmlParser(value) : index == 3 ? value === 'true' ? <CheckLg size={25} className='text-success'/> : <XLg size={20} className='text-danger'/> : value}</td>
                  )}
                </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>
    )
}