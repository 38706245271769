// React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.scss';

// React Select
import Select from 'react-select';

// React Tooltip
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

// Language mapping
import lang_mapping from '../lang_mapping.json';

// Logo
import logo from '../logo.svg';

// Set datasets length
var datasets_length = {
  flores200: 1012,
  NTREX: 1997,
  official: 817,
  custom: 0
}

// Official dataset languages
var official_langs = ["en", "de", "es", "fr", "it", "ja", "pt", "el", "ru", "gl"]

function SimpleForm({ allEngines , loadingEngines, host}) {

  // Navigation
  const navigate = useNavigate();

  // Filter engines
  var originalEngines = [];
  for (var i = 0; i < allEngines.length; i++) {
    originalEngines[i] = {};
    originalEngines[i].label = allEngines[i].descr + " | " + allEngines[i].src + "→"+ allEngines[i].tgt + " | (" + allEngines[i].id + ")";
    originalEngines[i].value = allEngines[i].id;
    originalEngines[i].src = allEngines[i].src.toLowerCase();
    originalEngines[i].tgt = allEngines[i].tgt.toLowerCase();
  }
  var filteredEngines = originalEngines;

  // Set available languages
  var available_src_langs = {};
  var available_tgt_langs = {};
  for (var i = 0; i < originalEngines.length; i++) {
    available_src_langs[originalEngines[i].src] = originalEngines[i].src;
    available_tgt_langs[originalEngines[i].tgt] = originalEngines[i].tgt;
  }

  // Set lang mapping
  var lang_codes = {}
  for (var i = 0; i < lang_mapping.length; i++) {
    var code = lang_mapping[i].code.toLowerCase();
    lang_codes[code] = {};
    lang_codes[code].label = lang_mapping[i].name;
    lang_codes[code].value = lang_mapping[i].code.toLowerCase();
    lang_codes[code].flores200 = lang_mapping[i].flores200;
    lang_codes[code].NTREX = lang_mapping[i].NTREX;
  }

  // Set languages options
  var lang_src_options = []
  for (var i = 0; i < lang_mapping.length; i++) {
    var code = lang_mapping[i].code.toLowerCase();
    if (code.split("-")[0] in available_src_langs) {
      lang_src_options[i] = {};
      lang_src_options[i].label = lang_mapping[i].name;
      lang_src_options[i].value = lang_mapping[i].code.toLowerCase();
    }
  }
  var lang_tgt_options = []
  for (var i = 0; i < lang_mapping.length; i++) {
    var code = lang_mapping[i].code.toLowerCase();
    if (code.split("-")[0] in available_tgt_langs) {
      lang_tgt_options[i] = {};
      lang_tgt_options[i].label = lang_mapping[i].name;
      lang_tgt_options[i].value = lang_mapping[i].code.toLowerCase();
    }
  }

  // Form data
  const [formData, setFormData] = useState({
    src_lang: '',
    tgt_lang: '',
    engines: [],
    dataset: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [fetchErrorMsg, setFetchErrorMsg] = useState('');
  const [fetchErrorCode, setFetchErrorCode] = useState(0);

  // Files
  const [srcFile, setSrcFile] = useState(null);
  const [refFile, setRefFile] = useState(null);

  // Request options
  const [requestName, setRequestName] = useState('');
  const [allSegments, setAllSegments] = useState(true);
  const [numSegments, setNumSegments] = useState(1);
  const [randomSelect, setRandomSelect] = useState(true);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [timeout, setTimeout] = useState(15);
  const [batchSize, setBatchSize] = useState(100);

  // Check dataset availability
  const [floresAvailable, setFloresAvailable] = useState(true);
  const [NTREXAvailable, setNTREXAvailable] = useState(true);
  const [officialAvailable, setOfficialAvailable] = useState(true);

  function random_name() {
    let length = 5;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  useEffect(() => {
    setRequestName('request_' + random_name());
  }, []);
  
  const onLangChange = (object, {action, name}) => {
    if (action === 'clear') {
      setFormData({
        ...formData,
        [name]: '',
      });
      // Check dataset availability
      if (name === 'src_lang' && formData.tgt_lang.length == 0) {
        setFloresAvailable(true);
        setNTREXAvailable(true);
        setOfficialAvailable(true);
      } else if (name === 'src_lang' && formData.tgt_lang.length > 0) {
        if (lang_codes[formData.tgt_lang].flores200 == "") {
          setFloresAvailable(false);
        } else {
          setFloresAvailable(true);
        }
        if (lang_codes[formData.tgt_lang].NTREX == "") {
          setNTREXAvailable(false);
        } else {
          setNTREXAvailable(true);
        }
        if (official_langs.includes(formData.tgt_lang.split('-')[0])) {
          setOfficialAvailable(true);
        } else {
          setOfficialAvailable(false);
        }
      }
      if (name === 'tgt_lang' && formData.src_lang.length == 0) {
        setFloresAvailable(true);
        setNTREXAvailable(true);
        setOfficialAvailable(true);
      } else if (name === 'tgt_lang' && formData.src_lang.length > 0) {
        if (lang_codes[formData.src_lang].flores200 == "") {
          setFloresAvailable(false);
        } else {
          setFloresAvailable(true);
        }
        if (lang_codes[formData.src_lang].NTREX == "") {
          setNTREXAvailable(false);
        } else {
          setNTREXAvailable(true);
        }
        if (official_langs.includes(formData.src_lang.split('-')[0])) {
          setOfficialAvailable(true);
        } else {
          setOfficialAvailable(false);
        }
      }
    } else {
      setFormData({
        ...formData,
        [name]: object.value,
      });
      setErrors({
        ...errors,
        [name]: null
      });
      // Check dataset availability
      if (name === 'src_lang' && formData.tgt_lang.length == 0) {
        if (lang_codes[object.value].flores200 == "") {
          setFloresAvailable(false);
        } else {
          setFloresAvailable(true);
        }
        if (lang_codes[object.value].NTREX == "") {
          setNTREXAvailable(false);
        } else {
          setNTREXAvailable(true);
        }
        if (official_langs.includes(object.value.split('-')[0])) {
          setOfficialAvailable(true);
        } else {
          setOfficialAvailable(false);
        }
      } else if (name === 'src_lang' && formData.tgt_lang.length > 0) {
        if (lang_codes[object.value].flores200 == "" || lang_codes[formData.tgt_lang].flores200 == "") {
          setFloresAvailable(false);
        } else {
          setFloresAvailable(true);
        }
        if (lang_codes[object.value].NTREX == "" || lang_codes[formData.tgt_lang].NTREX == "") {
          setNTREXAvailable(false);
        } else {
          setNTREXAvailable(true);
        }
        if (official_langs.includes(object.value.split('-')[0]) && official_langs.includes(formData.tgt_lang.split('-')[0])) { 
          setOfficialAvailable(true);
        } else {
          setOfficialAvailable(false);
        }
      }
      if (name === 'tgt_lang' && formData.src_lang.length == 0) {
        if (lang_codes[object.value].flores200 == "") {
          setFloresAvailable(false);
        } else {
          setFloresAvailable(true);
        }
        if (lang_codes[object.value].NTREX == "") {
          setNTREXAvailable(false);
        } else {
          setNTREXAvailable(true);
        }
        if (official_langs.includes(object.value.split('-')[0])) {      
          setOfficialAvailable(true);
        }
      } else if (name === 'tgt_lang' && formData.src_lang.length > 0) {
        if (lang_codes[object.value].flores200 == "" || lang_codes[formData.src_lang].flores200 == "") {
          setFloresAvailable(false);
        } else {
          setFloresAvailable(true);
        }
        if (lang_codes[object.value].NTREX == "" || lang_codes[formData.src_lang].NTREX == "") {
          setNTREXAvailable(false);
        } else {
          setNTREXAvailable(true);
        }
        if (official_langs.includes(object.value.split('-')[0]) && official_langs.includes(formData.src_lang.split('-')[0])) {      
          setOfficialAvailable(true);
        } else {
          setOfficialAvailable(false);
        }
      }
    }
  };

  const onEngineChange = (inputValue) => {
    setFormData({
      ...formData,
      engines: inputValue
    });
    setErrors({
      ...errors,
      engines: null
    });
  };

  const langFilter = (option, searchText) => {
    if (formData.src_lang.length > 0 || formData.tgt_lang.length > 0) {
      return option.data.src.includes(formData.src_lang.split('-')[0]) 
          && option.data.tgt.includes(formData.tgt_lang.split('-')[0])
          && option.data.label.toLowerCase().includes(searchText.toLowerCase());
    } else {
      return option.data.label.toLowerCase().includes(searchText.toLowerCase());
    }
  };

  const onSubmit = (event) => {

    var fd = new FormData();

    var submit = true;
    setLoading(true);

    if (formData.src_lang.length == 0) {
      setErrors({
        ...errors,
        src_lang: "Please select a source language"
      });
      submit = false;
    } else {
      fd.append("src_lang", formData.src_lang);
      if (formData.tgt_lang.length == 0 ) {
        setErrors({
          ...errors,
          tgt_lang: "Please select a target language"
        });
        submit = false;
      } else {
        fd.append("tgt_lang", formData.tgt_lang);
        if (formData.engines.length == 0) {
          setErrors({
            ...errors,
            engines: "Please select at least one engine"
          });
          submit = false;
        } else {
          var engines = []
          for (var i = 0; i < formData.engines.length; i++) {
            engines.push(formData.engines[i].value);
          }
          fd.append("engines", engines);
          if (formData.dataset.length == 0) {
            setErrors({
              ...errors,
              dataset: "Please select a dataset"
            });
            submit = false;
          } else {
            fd.append("dataset", formData.dataset);
          }}}}
    if (formData.dataset == "custom") {
      if (srcFile != null) {
        fd.append("src_file", srcFile);
        if (refFile != null) {
          fd.append("ref_file", refFile);
        } else {
          setErrors({
            ...errors,
            ref_file: "Please upload a reference file"
          });
          submit = false;
        }
      } else {
        setErrors({
          ...errors,
          src_file: "Please upload a source file"
        });
        submit = false;
      }
    } else {
      fd.append("src_file", "");
      fd.append("ref_file", "");
    }
    if (allSegments) {
      fd.append("segments", datasets_length[formData.dataset]);
    } else {
      if (errors.num_segments != null) {
        submit = false;
      } else {
        fd.append("segments", numSegments);
      }
    }
    fd.append("random", randomSelect);
    if (errors.timeout != null) {
      submit = false;
    } else {
      fd.append("timeout", timeout);
    }
    if (errors.batch_size != null) {
      submit = false;
    } else {
      fd.append("batch_size", batchSize);
    }
    if (errors.name != null) {
      submit = false;
    } else {
      fd.append("name", requestName);
    }

    if (submit) {
      var requestOptions = {
        method: 'POST',
        body: fd,
        redirect: 'follow'
      };

      fetch(host + "/eval", requestOptions)
        .then(response => {
          if (!response.ok) {
            response.json().then(r => setFetchErrorMsg(r.detail));
            setFetchErrorCode(response.status);
            setFetchError(true);
            setLoading(false);
            throw response;
          }
          return response.json();
        })
        .then(result => {
          navigate('/evaluation/' + result.hash)
        })
        .catch(error => {
          setFetchErrorCode(error.status);
          setFetchError(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }

  const handleOptionChange = (event) => {
    setSelectedDataset(event.target.value);
    setFormData({
      ...formData,
      dataset: event.target.value
    });
    setErrors({
      ...errors,
      dataset: null
    });
  };

  const onSrcUpload = e => {
    setSrcFile(e.target.files[0]);
    setErrors({
      ...errors,
      src_file: null
    });
  };

  const onRefUpload = e => {
    setRefFile(e.target.files[0]);
    setErrors({
      ...errors,
      ref_file: null
    });
  };

  const allChange = event => {
    setAllSegments(event.target.checked);
  };

  const numChange = event => {
    if (formData.dataset == "custom") {
      if (event.target.value < 1) {
        setErrors({
          ...errors,
          num_segments: "Must be greater than 0"
        });
      } else {
        setNumSegments(event.target.value);
        setErrors({
          ...errors,
          num_segments: null
        });
      }
    } else {
      if (event.target.value > datasets_length[formData.dataset]) {
        setErrors({
          ...errors,
          num_segments: "Must be less than or equal to " + datasets_length[formData.dataset]
        });
      } else if (event.target.value < 1) {
        setErrors({
          ...errors,
          num_segments: "Must be greater than 0"
        });
      } else {
        setNumSegments(event.target.value);
        setErrors({
          ...errors,
          num_segments: null
        });
      }
    }
  };

  const timeoutChange = event => {
    if (event.target.value < 1) {
      setErrors({
        ...errors,
        timeout: "Must be greater than 0"
      });
    } else {
      setTimeout(event.target.value);
      setErrors({
        ...errors,
        timeout: null
      });
    }
  };

  const randomChange = event => {
    setRandomSelect(event.target.checked);
  };


  const nameChange = event => {
    if (event.target.value.length > 50) {
      setErrors({
        ...errors,
        name: "Must be less than 50 characters"
      });
    } else if (event.target.value.length == 0) {
      setErrors({
        ...errors,
        name: "Please enter a name"
      });
      setRequestName('');
    } else {
      setRequestName(event.target.value);
      setErrors({
        ...errors,
        name: null
      });
    }
  };

  const batchChange = event => {
    if (event.target.value < 1) {
      setErrors({
        ...errors,
        batch_size: "Must be greater than 0"
      });
    } else {
      setBatchSize(event.target.value);
      setErrors({
        ...errors,
        batch_size: null
      });
    }
  };

  
  return (
        <Form as={Col}>
          <Row className='mb-5'> {/* Select languages */}
              <h2 className='h2 mb-4' style={{textAlign:"center", fontWeight: "bold"}}>Languages</h2>
              <Form.Group className='col-sm mb-3' controlId="src_lang">
                  <Select 
                    options={lang_src_options}
                    type="text"
                    classNamePrefix="select"
                    onChange={onLangChange}
                    isClearable={true}
                    isSearchable={true}
                    isLoading={loadingEngines}
                    placeholder="Source language"
                    name="src_lang"
                    required
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: errors.src_lang == null ? "#ccc" : 'red',
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ffc59b',
                        primary50: '#ff6a00',
                        primary: '#fa7618',
                      },
                    })}
                  />
                  <Form.Control type="hidden" isInvalid={!!errors.src_lang}></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.src_lang}
                  </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='col-sm' controlId="tgt_lang">
                  <Select 
                    options={lang_tgt_options}
                    type="text"
                    classNamePrefix="select"
                    onChange={onLangChange}
                    isClearable={true}
                    isLoading={loadingEngines}
                    isSearchable={true}
                    placeholder="Target language"
                    name="tgt_lang"
                    required
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: errors.tgt_lang == null ? "#ccc" : 'red',
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ffc59b',
                        primary50: '#ff6a00',
                        primary: '#fa7618',
                      },
                    })}
                  />
                  <Form.Control type="hidden" isInvalid={!!errors.tgt_lang}></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.tgt_lang}
                  </Form.Control.Feedback>
              </Form.Group>
          </Row>
          <Row className='mb-5'> {/* Select engines*/}
              <h2 className='h2 mb-4' style={{textAlign:"center", fontWeight: "bold"}}>MT engines</h2>
              <Form.Group controlId="engines">
                  <Select
                    isMulti
                    name="engines"
                    placeholder="Engines to be evaluated"
                    options={filteredEngines}
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    isLoading={loadingEngines}
                    onChange={onEngineChange}
                    filterOption={langFilter}
                    closeMenuOnSelect={false}
                    required
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: errors.engines == null ? "#ccc" : 'red',
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ffc59b',
                        primary50: '#ff6a00',
                        primary: '#fa7618',
                      },
                    })}
                  />
                  <Form.Control type="hidden" isInvalid={!!errors.engines}></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.engines}
                  </Form.Control.Feedback>
              </Form.Group>
          </Row>
          <Row className='mb-5 justify-content-md-center'> {/* Select dataset */}
              <h2 className='h2 mb-5' style={{textAlign:"center", fontWeight: "bold"}}>Datasets</h2>
              <Tooltip
                id="dataset-tooltip"
                place="bottom"
                style={{borderRadius: '5px', maxWidth: '200px', textAlign: 'center'}}
                render={({ activeAnchor }) => (
                  <span>
                    <b>{activeAnchor?.getAttribute('dataset-creator') || 'unknown'}</b>
                    <br/>
                    # segments: {activeAnchor?.getAttribute('dataset-segments') || 'unknown'}
                  </span>
                )}
              />
              <Form.Group as={Col} style={{textAlign: "center"}} controlId='flores200'>
                <Form.Label
                  data-tooltip-id="dataset-tooltip"
                  dataset-creator="Meta"
                  dataset-segments="1012"
                  >
                  <Form.Check
                    type="radio"
                    name="dataset"
                    value="flores200"
                    checked={selectedDataset === 'flores200'}
                    onChange={handleOptionChange}
                    disabled={floresAvailable === false}
                    isInvalid={!!errors.dataset}
                    label="Flores200"
                    required
                  />
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} style={{textAlign: "center"}} controlId='NTREX'>
                <Form.Label
                  data-tooltip-id="dataset-tooltip"
                  dataset-creator="Microsoft"
                  dataset-segments="1997"
                  >
                  <Form.Check
                    type="radio"
                    name="dataset"
                    value="NTREX"
                    checked={selectedDataset === 'NTREX'}
                    onChange={handleOptionChange}
                    disabled={NTREXAvailable === false}
                    isInvalid={!!errors.dataset}
                    label="NTREX"
                  />
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} style={{textAlign: "center"}} controlId='official'>
                <Form.Label
                  data-tooltip-id="dataset-tooltip"
                  dataset-creator="Pangeanic"
                  dataset-segments="817"
                  >
                  <Form.Check
                    type="radio"
                    name="dataset"
                    value="official"
                    checked={selectedDataset === 'official'}
                    onChange={handleOptionChange}
                    disabled={officialAvailable === false}
                    isInvalid={!!errors.dataset}
                    label="Official test"
                  />
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} style={{textAlign: "center"}} controlId='custom'>
                <Form.Label>
                  <Form.Check
                    type="radio"
                    name="dataset"
                    value="custom"
                    checked={selectedDataset === 'custom'}
                    onChange={handleOptionChange}
                    isInvalid={!!errors.dataset}
                    label="Upload dataset"
                  />
                </Form.Label>
              </Form.Group>
              {selectedDataset == "custom" ? 
              <Row className='my-4'>
                <Form.Group as={Col} controlId='src_file'>
                  <Form.Label>Source file</Form.Label>
                  <Form.Control type='file' name='src_file' onChange={onSrcUpload} isInvalid={!!errors.src_file} required />
                  <Form.Control.Feedback type="invalid">
                    {errors.src_file}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId='ref_file'>
                  <Form.Label>Reference file</Form.Label>
                  <Form.Control type='file' name='ref_file' onChange={onRefUpload} isInvalid={!!errors.ref_file} required />
                  <Form.Control.Feedback type="invalid">
                    {errors.ref_file}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              : null}
          </Row>
          <Row> {/* Select settings */}
            <h3 className='h4 mb-5' style={{textAlign:"center", fontWeight: "bold"}}>Settings</h3>
            <Row className='mb-5'>
              <Col className="d-flex justify-content-md-center">
                <Form.Group as={Stack} direction="horizontal" gap={2}>
                  <Form.Label htmlFor="request_name" className='my-0'>
                    Request name
                  </Form.Label>
                  <Form.Control
                    type="text" 
                    id='request_name'
                    className="request_name"
                    style={{width: "350px"}}
                    onInput={nameChange} 
                    value={requestName}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid" className='w-25'>
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className="d-flex justify-content-md-center mb-2">
              <Form.Group as={Stack} direction="horizontal" gap={2}>
                  <Form.Label htmlFor="timeout_min" className='my-0'>
                    Timeout (min)
                  </Form.Label>
                  <Form.Control
                    type="number" 
                    id='timeout_min'
                    className="timeout_min"
                    style={{width: "150px"}}
                    onInput={timeoutChange} 
                    value={timeout}
                    min={1}
                    pattern='[0-9]*'
                    isInvalid={!!errors.timeout}
                    onKeyDown={(e) => {
                      // If the user press delete and the input is lower than 10 we set it to 1
                      if (e.key === 'Backspace' && timeout < 10) {
                        setTimeout(1);
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid" className='w-25'>
                    {errors.timeout}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-md-center mb-2">
              <Form.Group as={Stack} direction="horizontal" gap={2}>
                  <Form.Label htmlFor="batch_size" className='my-0'>
                    Batch size
                  </Form.Label>
                  <Form.Control
                    type="number" 
                    id='batch_size'
                    className="batch_size"
                    style={{width: "150px"}}
                    onInput={batchChange} 
                    value={batchSize}
                    min={1}
                    pattern='[0-9]*'
                    isInvalid={!!errors.batch_size}
                    onKeyDown={(e) => {
                      // If the user press delete and the input is lower than 10 we set it to 1
                      if (e.key === 'Backspace' && batchSize < 10) {
                        setBatchSize(1);
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid" className='w-25'>
                    {errors.batch_size}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-md-center mb-2">
                <Form.Group>
                    <Form.Check
                      type="switch"
                      label="Translate all segments"
                      className="translate_all"
                      onChange={allChange}
                      checked={allSegments === true} />
                </Form.Group>
              </Col>
            </Row>
            {allSegments === true ? null :
            <Row className='align-items-center mb-5'>
              <Col className="d-flex flex-row-reverse">
              <Form.Group as={Stack} direction="horizontal" gap={2}>
                <Form.Label htmlFor="num_segments" className='my-0'>
                  Number of segments
                </Form.Label>
                <Form.Control
                  type="number" 
                  id='num_segments'
                  className="num_segments"
                  style={{width: "150px"}}
                  onInput={numChange} 
                  value={numSegments}
                  max={selectedDataset == "custom"? null : datasets_length[selectedDataset]}
                  min={1}
                  pattern='[0-9]*'
                  isInvalid={!!errors.num_segments}
                  onKeyDown={(e) => {
                    // If the user press delete and the input is lower than 10 we set it to 1
                    if (e.key === 'Backspace' && numSegments < 10) {
                      setNumSegments(1);
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid" className='w-25'>
                  {errors.num_segments}
                </Form.Control.Feedback>
              </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} className='d-flex '>
                  <Form.Check 
                    type="switch"
                    label="Random select"
                    className="random_select" 
                    onChange={randomChange} 
                    checked={randomSelect === true} 
                  />
                </Form.Group>
              </Col>
            </Row>
            }
          </Row>
          <Row className='justify-content-md-center mb-5 mt-2'> {/* Submit button */}
            {loading ? 
            <Spinner animation="border" role='status' style={{color: "#fa7618"}}>
              <span className="visually-hidden">Loading...</span>
            </Spinner> : 
            <Button type="submit" className='btn btn-primary btn-lg' onClick={onSubmit}
              style={{
                borderRadius: "5px", 
                width: "200px",
                color: "white"
              }}
            >
              Submit
            </Button>
            }
          </Row>
            <Alert 
              variant='danger' 
              show={fetchError} 
              onClose={() => setFetchError(false)} 
              dismissible
              style={{
                maxWidth: "600px",
                minWidth: "350px",
                top: "50px",
                right: "50px",
                position:"fixed",
              }}>
              <Alert.Heading>You got an {fetchErrorCode} error!</Alert.Heading>
              <p>
                {fetchErrorMsg}
              </p>
            </Alert>
        </Form>
  );
}


function Request({ host }) {
  const [allEngines, setallEngines] = useState([]);
  const [loadingEngines, setLoadingEngines] = useState(true);

  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  };
  useEffect(() => {
    fetch("http://prod.pangeamt.com:8080/NexRelay/v1/corp/engines?apikey=d1d0a149-35c9-4276-af75-4457da20d96a", requestOptions)
      .then(response => response.json())
      .then(result => {
        setallEngines(result.engines)
        setLoadingEngines(false)})
      .catch(error => console.log('error', error));
  }, []);

    return (
      <div className="App">
        <Navbar bg='lg' className='py-5'>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" width="160" className='d-inline-block align-top' />{''}
              <p className='h2 d-inline-block' style={{fontWeight: "bold", margin: "40px 0 0 20px"}}>MT Evaluation</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Button variant="outline-primary" href="/list" style={{marginTop: "40px"}}>Requests list</Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className='justify-content-center w-75 mt-4'>
          <SimpleForm allEngines={allEngines} loadingEngines={loadingEngines} host={host}/>
        </Container>
      </div>
    );
}
  
export default Request;
