// React
import React, { useState, useEffect, useRef } from 'react';
import '../App.scss';
import { Link } from 'react-router-dom';

// React Tooltip
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BoxArrowUpRight, PencilSquare, Clipboard, Trash3 } from 'react-bootstrap-icons';

// Logo
import logo from '../logo.svg';


export default function ListRequests({ host }) {

    // Data states
    const [loadingData, setLoading] = useState(true);
    const [allRequests, setAllRequests] = useState({});
    const [unfinishedRequests, setUnfinishedRequests] = useState({});

    const [showAll, setShowAll] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [modalRequest, setModalRequest] = useState({});
    const [success, setSuccess] = useState('');
    const [deleteSuccess, setDelete] = useState('');

    // Fetch error states
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMsg, setFetchErrorMsg] = useState('');
    const [fetchErrorCode, setFetchErrorCode] = useState(0);


    useEffect(() => {
      fetch(host + "/list_all", {
        method: 'GET',
        origin: 'http://' + window.location.hostname + ':' + window.location.port})
        .then(response => {
          if (response.status == 200) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then(result => {
          setLoading(false);
          setAllRequests(result.reverse());
          for (const [key, value] of Object.entries(result)) {
            if (!value.finished) {
              setUnfinishedRequests(prevState => ({...prevState, [key]: value}));
            }
          }
        })
        .catch(error => {
          setFetchError(true);
          setLoading(false);
          if (error instanceof TypeError) {
            setFetchErrorMsg(error.message);
            setFetchErrorCode(500);
          } else {
            setFetchErrorMsg(error.statusText);
            setFetchErrorCode(error.status);
          }
        });
    }, [success, deleteSuccess]);

    useEffect(() => {
      document.title = "Evaluations list";
    }, []);

    const deleteRequest = (hash) => {
      var requestOptions = {
        method: 'POST',
        redirect: 'follow'
      };
      
      fetch(host + "/evaluation/delete/" + hash, requestOptions)
        .then(response => {
          if (!response.ok) {
            response.json().then(r => setFetchErrorMsg(r.detail));
            setFetchErrorCode(response.status);
            setFetchError(true);
            throw response;
          }
          return response.json();
        })
        .then(result => {
          setDelete("Request deleted successfully");
        })
        .catch(error => {
          setFetchErrorCode(error.status);
          setFetchError(true);
        });
    }


    const onNameSave = (newName) => {
      
      if (newName.length > 0) {

        var fd = new FormData();

        fd.append('name', newName);

        var requestOptions = {
          method: 'POST',
          body: fd,
          redirect: 'follow'
        };
  
        fetch(host + "/evaluation/change_name/" + modalRequest.hash, requestOptions)
          .then(response => {
            if (!response.ok) {
              response.json().then(r => setFetchErrorMsg(r.detail));
              setFetchErrorCode(response.status);
              setFetchError(true);
              throw response;
            }
            return response.json();
          })
          .then(result => {
            setSuccess("Name changed successfully");
            setModalShow(false);
          })
          .catch(error => {
            setFetchErrorCode(error.status);
            setFetchError(true);
          });
      }
    }


    function ChangeNameModal(props) {

      
      const [newName, setNewName] = useState('');
      const [error_name, setErrorsName] = useState('');

      const nameChange = event => {
        if (event.target.value.length > 0) {
          setErrorsName('');
          setNewName(event.target.value);
        } else {
          setErrorsName('Name cannot be empty');
          setNewName("");
        }
      };

      return (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <p className='h3' style={{fontWeight: "bold"}}>Change request name </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Current name: {modalRequest.name ? modalRequest.name : null }</h5>
            <hr />
            <Form>
              <Form.Group className="mb-3" controlId="name_input">
                <Form.Label>New name</Form.Label>
                <Form.Control type="text" placeholder="Enter name" isInvalid={!!error_name} onInput={nameChange} value={newName} autoFocus required/>
                <Form.Control.Feedback type="invalid">
                  {error_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} style={{color: '#fff'}} variant='secondary'>Close</Button>
            <Button onClick={() => onNameSave(newName)} style={{color: '#fff'}} variant='success'>Save</Button>
          </Modal.Footer>
        </Modal>
      );
    }    

    return (
        <div className="App">
        <Navbar bg='lg' className='py-5'>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" width="160" className='d-inline-block align-top' />{''}
              <p className='h2 d-inline-block' style={{fontWeight: "bold", margin: "40px 0 0 20px"}}>MT Evaluation</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Button variant="outline-primary" href="/" style={{marginTop: "40px"}}>New evaluation</Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
          <Alert 
            variant='danger' 
            show={fetchError} 
            onClose={() => setFetchError(false)} 
            dismissible 
            style={{
              maxWidth: "600px",
              minWidth: "350px",
              top: "50px",
              right: "50px",
              position:"fixed",
            }}>
            <Alert.Heading>You got an {fetchErrorCode} error!</Alert.Heading>
            <p>
              {fetchErrorMsg}
            </p>
          </Alert>
          <Alert 
            variant='danger' 
            show={deleteSuccess} 
            onClose={() => setDelete(false)} 
            dismissible 
            style={{
              maxWidth: "600px",
              minWidth: "350px",
              top: "50px",
              right: "50px",
              position:"fixed",
            }}>
            <Alert.Heading>Request deleted!</Alert.Heading>
          </Alert>
          <Alert 
            variant='success' 
            show={success} 
            onClose={() => setSuccess('')} 
            dismissible 
            style={{
              maxWidth: "600px",
              minWidth: "350px",
              top: "50px",
              right: "50px",
              position:"fixed",
            }}>
            <Alert.Heading>{success}</Alert.Heading>
          </Alert>
          <Container className='justify-content-md-center w-75'>
            <Row>
              <Col className='d-flex justify-content-center'>
                <p className='display-6' style={{fontWeight: "bold"}}>Requests list</p>
              </Col>
            </Row>
            {loadingData ? 
            <Row>
              <Col className='d-flex justify-content-center my-5'>
                <Stack gap={4} direction="horizontal">
                  <Spinner animation="border" role="status" style={{color: "#fa7618"}}>
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p className='display-6 mt-3'>Loading...</p>
                </Stack>
              </Col>
            </Row>
            :
            <Row>
              <Col className='d-flex justify-content-center my-3'>
                <Stack gap={4} direction="vertical" className='mx-auto col-md-5'>
                  <Row>
                    <Col className='d-flex justify-content-center'>
                        <Form.Check
                          type='switch'
                          checked={showAll}
                          onChange={() => setShowAll(!showAll)}
                          label="Only unfinished requests"
                        />
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-center scrollme'>
                    { Object.keys(allRequests).length == 0 ?
                    <Row className='d-flex justify-content-center'>
                      <Col className='d-flex justify-content-center mt-5'>
                        <p className='display-6 mt-3'>No requests found</p>
                      </Col>
                    </Row>
                    : showAll ?
                    <Table striped bordered responsive hover>
                      <thead>
                        <tr>
                          <th className="text-center">Name</th>
                          <th className="text-center">Source language</th>
                          <th className="text-center">Target language</th>
                          <th className="text-center">Dataset</th>
                          <th className="text-center"># segments</th>
                          <th className="text-center">Creation date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Settings</th>
                        </tr>
                      </thead>
                      <tbody>
                        { Object.entries(allRequests).map(([request, values]) =>
                        <tr key={request.hash}>
                          <td className="text-center"> {values.name} </td>
                          <td className="text-center"> {values.src_lang} </td>
                          <td className="text-center"> {values.tgt_lang} </td>
                          <td className="text-center"> {values.dataset} </td>
                          <td className="text-center"> {values.dataset_segments ? values.dataset_segments : values.num_segments == 0 ? "LOADING" : values.num_segments } </td>
                          <td className="text-center"> {values.time_created} </td>
                          {values.finished ? 
                          values.error ? 
                          <td className="text-center table-danger" data-tooltip-id='error-tooltip' error-message={values.error}>
                            <Tooltip
                              id="error-tooltip"
                              place="bottom"
                              style={{borderRadius: '5px', textAlign: 'center', maxWidth: '300px'}}
                              render={({ activeAnchor }) => (
                                <span>
                                  { activeAnchor?.getAttribute('error-message') || 'Unknown' }
                                </span>
                              )}
                            />
                            ERROR
                          </td>
                          :
                          <td className="text-center table-success">FINISHED</td>
                          :
                          <td className="text-center table-info">RUNNING</td>
                          } 
                          <td className="text-center">
                          <Tooltip
                            id="settings-tooltip"
                            place="bottom"
                            style={{borderRadius: '5px', textAlign: 'center'}}
                            render={({ activeAnchor }) => (
                              <span style={ activeAnchor?.getAttribute('id') == "delete-button" ? {color: "#FA5858"} : null}>
                                { activeAnchor?.getAttribute('data-tooltip') || 'Settings' }
                              </span>
                            )}
                          />
                            <Stack gap={4} direction="horizontal" className='justify-content-center'>
                              <Link 
                                to={`/evaluation/${values.hash}`} 
                                data-tooltip-id='settings-tooltip'
                                data-tooltip='View evaluation'
                                >
                                <BoxArrowUpRight size={17} className='text-secondary'/>
                              </Link>
                              {/* <Button 
                                onClick={() => { navigator.clipboard ? navigator.clipboard.writeText(`${location.protocol}//${location.host}/evaluation/${values.hash}`) : console.log('Clipboard API not available');}}
                                data-tooltip-id='settings-tooltip'
                                data-tooltip={ navigator.clipboard ? 'Copy link' : 'Clipboard API not available'}
                                style={{border: 'none', background: 'none', padding: '0'}}
                                >
                                <Clipboard size={17} className='text-secondary'/>
                              </Button> */}
                              <Button 
                                onClick={() => {
                                  setModalShow(true)
                                  setModalRequest(values)
                                }}
                                style={{border: 'none', background: 'none', padding: '0'}}
                                data-tooltip-id='settings-tooltip'
                                data-tooltip='Edit name'
                                >
                                <PencilSquare size={17} className='text-secondary'/>
                              </Button>
                              <Button 
                                onClick={ () => { 
                                  if (deleteSuccess) setDeleteSuccess(false)
                                  deleteRequest(values.hash) 
                                }}
                                id='delete-button'
                                style={{border: 'none', background: 'none', padding: '0'}}
                                data-tooltip-id='settings-tooltip'
                                data-tooltip='Delete request'
                                >
                                <Trash3 size={17} className='text-secondary'/>
                              </Button>
                            </Stack>
                          </td>
                        </tr>
                        )}
                      </tbody>
                    </Table>
                    : Object.keys(unfinishedRequests).length > 0 ?
                    <Table striped bordered responsive hover>
                      <thead>
                        <tr>
                          <th className="text-center">Name</th>
                          <th className="text-center">Source language</th>
                          <th className="text-center">Target language</th>
                          <th className="text-center">Dataset</th>
                          <th className="text-center"># segments</th>
                          <th className="text-center">Creation date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Settings</th>
                        </tr>
                      </thead>
                      <tbody>
                        { Object.entries(unfinishedRequests).map(([request, values]) =>
                        <tr key={request.hash}>
                          <td className="text-center"> {values.name} </td>
                          <td className="text-center"> {values.src_lang} </td>
                          <td className="text-center"> {values.tgt_lang} </td>
                          <td className="text-center"> {values.dataset} </td>
                          <td className="text-center"> {values.dataset_segments ? values.dataset_segments : values.num_segments == 0 ? "LOADING" : values.num_segments }  </td>
                          <td className="text-center"> {values.time_created} </td>
                          {values.finished ? 
                          values.error ? 
                          <td className="text-center table-danger">ERROR</td>
                          :
                          <td className="text-center table-success">FINISHED</td>
                          :
                          <td className="text-center table-info">RUNNING</td>
                          } 
                          <td className="text-center">
                          <Tooltip
                            id="settings-tooltip"
                            place="bottom"
                            style={{borderRadius: '5px', textAlign: 'center'}}
                            render={({ activeAnchor }) => (
                              <span style={ activeAnchor?.getAttribute('id') == "delete-button" ? {color: "#FA5858"} : null}>
                                { activeAnchor?.getAttribute('data-tooltip') || 'Settings' }
                              </span>
                            )}
                          />
                            <Stack gap={4} direction="horizontal" className='justify-content-center'>
                              <Link 
                                to={`/evaluation/${values.hash}`} 
                                data-tooltip-id='settings-tooltip'
                                data-tooltip='View evaluation'
                                >
                                <BoxArrowUpRight size={17} className='text-secondary'/>
                              </Link>
                              {/* <Button 
                                onClick={() => { navigator.clipboard ? navigator.clipboard.writeText(`${location.protocol}//${location.host}/evaluation/${values.hash}`) : console.log('Clipboard API not available');}}
                                data-tooltip-id='settings-tooltip'
                                data-tooltip={ navigator.clipboard ? 'Copy link' : 'Clipboard API not available'}
                                style={{border: 'none', background: 'none', padding: '0'}}
                                >
                                <Clipboard size={17} className='text-secondary'/>
                              </Button> */}
                              <Button 
                                onClick={() => {
                                  setModalShow(true)
                                  setModalRequest(values)
                                }}
                                style={{border: 'none', background: 'none', padding: '0'}}
                                data-tooltip-id='settings-tooltip'
                                data-tooltip='Edit name'
                                >
                                <PencilSquare size={17} className='text-secondary'/>
                              </Button>
                              <Button 
                                onClick={ () => { 
                                  if (deleteSuccess) setDeleteSuccess(false)
                                  deleteRequest(values.hash) 
                                }}
                                id='delete-button'
                                style={{border: 'none', background: 'none', padding: '0'}}
                                data-tooltip-id='settings-tooltip'
                                data-tooltip='Delete request'
                                >
                                <Trash3 size={17} className='text-secondary'/>
                              </Button>
                            </Stack>
                          </td>
                        </tr>
                        )}
                      </tbody>
                    </Table>
                    :
                    <Row className='d-flex justify-content-center'>
                      <Col className='d-flex justify-content-center mt-5'>
                        <p className='display-6 mt-3'>No requests running</p>
                      </Col>
                    </Row>
                    }
                  </Row>
                </Stack>
              </Col>
            </Row>
            }
            <ChangeNameModal
              show={modalShow}
              onHide={() => {
                setModalShow(false)
                setModalRequest({})
              }}
            />

          </Container>
        </div>
        );
}