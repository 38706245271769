
import React, { useState } from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Request from './components/Request';
import ListRequests from './components/ListRequests';
import Evaluation from './components/Evaluation';
import ShowDiff from './components/ShowDiff';
import DataAug from './components/DataAug';

const App = () => {

  const [host, setHost] = useState('http://192.168.100.137:8080');

  return (
    <>
      <Routes>
        <Route path="/" element={<Request host={host} />} />
        <Route path="/list" element={<ListRequests host={host} />} />
        <Route path="/evaluation/" element={""} />
        <Route path="/evaluation/:id" element={<Evaluation host={host} />} />
        <Route path="/diff" element={<ShowDiff/>} />
        <Route path="/da" element={<DataAug/>} />
      </Routes>
    </>
  );
}

export default App;