// React
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../App.scss';

// React Tooltip
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

// HTML React Parser
import ReactHtmlParser from 'react-html-parser';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { ArrowRight, ArrowUp, ArrowDown } from 'react-bootstrap-icons';

// Charts
import ReactECharts from 'echarts-for-react';

// Language mapping
import lang_mapping from '../lang_mapping.json';

// Set datasets length
var datasets_length = {
  flores200: 1012,
  NTREX: 1997,
  official: 817,
  custom: 0
}

// Set lang mapping
var lang_codes = {}
for (var i = 0; i < lang_mapping.length; i++) {
  var code = lang_mapping[i].code.toLowerCase();
  lang_codes[code] = lang_mapping[i].rtl;
}

// Interval hook
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Evaluation({ host }) {

    // Data states
    const [loadingData, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [dataReceived, setDataReceived] = useState(false);
    const [showSegments, setShowSegments] = useState(true);

    // Evaluation states
    const [evaluating, setEvaluating] = useState(true);
    const [evaluatingError, setEvaluatingError] = useState(false);
    const [evaluatingErrorMsg, setEvaluatingErrorMsg] = useState('');
    const [evaluatingErrorCode, setEvaluatingErrorCode] = useState(0);
    const [requestDelay, setRequestDelay] = useState(5000);

    // Fetch error states
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMsg, setFetchErrorMsg] = useState('');
    const [fetchErrorCode, setFetchErrorCode] = useState(0);

    const [allEngines, setallEngines] = useState([]);
    const [enginesName, setEnginesName] = useState({});
    const [enginesService, setEnginesService] = useState({});
    const [requestEngines, setRequestEngines] = useState({});

    // Show diff
    const [showDiff, setShowDiff] = useState(true);

    // Charts
    const [BLEUData, setBLEUData] = useState({});
    const [CHRFData, setCHRFData] = useState({});
    const [TERData, setTERData] = useState({});

    var enginesOptions = {
      method: 'POST',
      redirect: 'follow'
    };
    useEffect(() => {
      fetch("http://prod.pangeamt.com:8080/NexRelay/v1/corp/engines?apikey=d1d0a149-35c9-4276-af75-4457da20d96a", enginesOptions)
        .then(response => response.json())
        .then(result => {
          let enginesName = {};
          let enginesService = {};
          for (var i = 0; i < result.engines.length; i++) {
            enginesName[result.engines[i].id] = result.engines[i].descr;
            enginesService[result.engines[i].id] = result.engines[i].serviceid;
          }
          setEnginesName(enginesName);
          setEnginesService(enginesService);
          setallEngines(result.engines);
        })
        .catch(error => console.log('error', error));
    }, []);

    let { id } = useParams();

   
    useInterval(() => {
      fetch(host + "/evaluation/" + id, {
        method: 'GET',
        origin: 'http://' + window.location.hostname + ':' + window.location.port})
        .then(response => {
          if (response.status == 200) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then(result => {
          setData(result);
          setDataReceived(true);
          setLoading(false);
          let requestEngines = {};
          for (var i = 0; i < result.engines.length; i++) {
            requestEngines[result.engines[i]] = true;
          }
          setRequestEngines(requestEngines);
          if (data.error) {
            setEvaluating(false);
            setEvaluatingError(true);
            setEvaluatingErrorMsg(data.error);
            setEvaluatingErrorCode('500');
          } else if (data.stats) {
            setEvaluating(false);
          } else {
            setRequestDelay(requestDelay);
          }
          if (data.finished) {
            setRequestDelay(null);
          } else { 
            setRequestDelay(requestDelay);
          }
        })
        .catch(error => {
          setRequestDelay(null);
          setFetchError(true);
          setLoading(false);
          if (error instanceof TypeError) {
            setFetchErrorMsg(error.message);
            setFetchErrorCode(500);
          } else {
            setFetchErrorMsg(error.statusText);
            setFetchErrorCode(error.status);
          }
        });
    }, requestDelay);

    useEffect(() => {
      document.title = data?.name || "Evaluation results";
    }, [data]);

    // Diff method
    function diffline(line1, line2) {
      const diff = computeDiff(Array.from(line1), Array.from(line2));
      const diffval = diff.values;
      const diffmask = diff.mask;

      let n = diffval.length;
      let pmc = 0;
      let result = '';
  
      for (let i = 0; i < n; i++) {
          let mc = diffmask[i];
  
          if (mc !== pmc) {
              switch (pmc) {
                  case -1: result += '</del>'; break;
                  case 1: result += '</ins>'; break;
              }
              switch (mc) {
                  case -1: result += '<del>'; break;
                  case 1: result += '<ins>'; break;
              }
          }
          result += diffval[i];
          pmc = mc;
      }
  
      switch (pmc) {
          case -1: result += '</del>'; break;
          case 1: result += '</ins>'; break;
      }
  
      return result;
  }

  
  
  function computeDiff(from, to) {
      let diffValues = [];
      let diffMask = [];
  
      let dm = [];
      let n1 = from.length;
      let n2 = to.length;
  
      for (let i = -1; i < n1; i++) {
          dm[i] = [];
          dm[i][-1] = 0;
      }
      for (let j = -1; j < n2; j++) {
          dm[-1][j] = 0;
      }
  
      for (let i = 0; i < n1; i++) {
          for (let j = 0; j < n2; j++) {
              if (from[i] === to[j]) {
                  let ad = dm[i - 1][j - 1];
                  dm[i][j] = ad + 1;
              } else {
                  let a1 = dm[i - 1][j];
                  let a2 = dm[i][j - 1];
                  dm[i][j] = Math.max(a1, a2);
              }
          }
      }
  
      let i = n1 - 1;
      let j = n2 - 1;
  
      while (i > -1 || j > -1) {
          if (j > -1) {
              if (dm[i][j - 1] === dm[i][j]) {
                  diffValues.push(to[j]);
                  diffMask.push(1);
                  j--;
                  continue;
              }
          }
  
          if (i > -1) {
              if (dm[i - 1][j] === dm[i][j]) {
                  diffValues.push(from[i]);
                  diffMask.push(-1);
                  i--;
                  continue;
              }
          }
  
          diffValues.push(from[i]);
          diffMask.push(0);
          i--;
          j--;
      }
  
      diffValues = diffValues.reverse();
      diffMask = diffMask.reverse();
  
      return { values: diffValues, mask: diffMask };
    }


    var stats = {};
    stats["bleu"] = []
    stats["chrf"] = []
    stats["ter"] = []

    useEffect(() => {
      // BLEU chart
      if (data.stats) {
        for (var engine in data.stats) {
          if (!data.stats[engine].error) {
            var bleu = 0.0;
            for (var seg in data.seg_analysis) {
              bleu += data.seg_analysis[seg].engines[engine].bleu
            }
            stats["bleu"].push({
              name: enginesName[engine],
              value: bleu
            })
            var chrf = 0.0;
            for (var seg in data.seg_analysis) {
              chrf += data.seg_analysis[seg].engines[engine].chrf
            }
            stats["chrf"].push({
              name: enginesName[engine],
              value: chrf
            })
            var ter = 0.0;
            for (var seg in data.seg_analysis) {
              ter += data.seg_analysis[seg].engines[engine].ter
            }
            stats["ter"].push({
              name: enginesName[engine],
              value: ter
            })
          }
        }
        // Normalize TER values
        var terSum = 0.0;
        for (var i = 0; i < stats["ter"].length; i++) {
          terSum += stats["ter"][i].value
        }
        for (var i = 0; i < stats["ter"].length; i++) {
          stats["ter"][i].value = stats["ter"][i].value / terSum
        }
        // Normalize BLEU values
        var bleuSum = 0.0;
        for (var i = 0; i < stats["bleu"].length; i++) {
          bleuSum += stats["bleu"][i].value
        }
        for (var i = 0; i < stats["bleu"].length; i++) {
          stats["bleu"][i].value = stats["bleu"][i].value / bleuSum
        }
        // Normalize CHRF values
        var chrfSum = 0.0;
        for (var i = 0; i < stats["chrf"].length; i++) {
          chrfSum += stats["chrf"][i].value
        }
        for (var i = 0; i < stats["chrf"].length; i++) {
          stats["chrf"][i].value = stats["chrf"][i].value / chrfSum
        }
        // Represent data in percentage with 2 decimals
        for (var i = 0; i < stats["ter"].length; i++) {
          stats["ter"][i].value = (stats["ter"][i].value * 100).toFixed(2)
        }
        for (var i = 0; i < stats["bleu"].length; i++) {
          stats["bleu"][i].value = (stats["bleu"][i].value * 100).toFixed(2)
        }
        for (var i = 0; i < stats["chrf"].length; i++) {
          stats["chrf"][i].value = (stats["chrf"][i].value * 100).toFixed(2)
        }
        setBLEUData({
          title: {
            text: 'BLEU',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: "<strong>{b}</strong>: {c}%"
          },
          series: [
            {
              name: 'BLEU',
              type: 'pie',
              radius: '70%',
              data: stats["bleu"],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
        setCHRFData({
          title: {
            text: 'CHRF',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: "<strong>{b}</strong>: {c}%"
          },
          series: [
            {
              name: 'CHRF',
              type: 'pie',
              radius: '70%',
              data: stats["chrf"],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
        setTERData({
          title: {
            text: 'TER',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: "<strong>{b}</strong>: {c}%"
          },
          series: [
            {
              name: 'TER',
              type: 'pie',
              radius: '70%',
              data: stats["ter"],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
      }
    }, [data])


    return (
      <div className="App">
        <Navbar bg='lg' className='pt-5'>
          <Container className='justify-content-md-center'>
            <Navbar.Brand className='header_title '>
              <p className='display-4' style={{fontWeight: "bold"}}>Evaluation results</p>
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Row className='justify-content-md-center'>
          <Alert 
            variant='danger' 
            show={fetchError} 
            onClose={() => setFetchError(false)} 
            style={{
              maxWidth: "600px",
              minWidth: "350px",
              top: "50px",
              right: "50px",
              position:"fixed",
            }}>
            <Alert.Heading>You got an {fetchErrorCode} error!</Alert.Heading>
            <p>
              {fetchErrorMsg}
            </p>
          </Alert>
        </Row>
        <Container className='justify-content-center w-75'>
          { loadingData ?
          <Row>
            <Col className='d-flex justify-content-center my-5'>
              <Stack gap={4} direction="horizontal">
                <Spinner animation="border" role="status" style={{color: "#fa7618"}}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p className='display-6 mt-3'>Loading...</p>
              </Stack>
            </Col>
          </Row>
          : !dataReceived ? null :
          <>
            <Row>
              <Col className='d-flex justify-content-center my-1'>
                <Stack gap={4} direction="horizontal">
                  <p className='mt-3' style={{fontWeight: "bold", fontSize: "28px"}}>{data.src_lang.toUpperCase()}</p>
                  <ArrowRight size={40}></ArrowRight>
                  <p className='mt-3' style={{fontWeight: "bold", fontSize: "28px"}}>{data.tgt_lang.toUpperCase()}</p>
                </Stack>
              </Col>
            </Row>
            <Tabs defaultActiveKey="statistics" id="tabs" className="my-3" fill>
              <Tab eventKey="statistics" title="Automatic statistics">
              { evaluatingError ?
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Alert 
                    variant='danger' 
                    show={evaluatingError} 
                    onClose={() => setEvaluatingError(false)} 
                    style={{
                      maxWidth: "600px",
                      minWidth: "350px",
                      top: "50px",
                      right: "50px",
                      position:"fixed",
                    }}>
                    <Alert.Heading>You got an {evaluatingErrorCode} error!</Alert.Heading>
                    <p>
                      {evaluatingErrorMsg}
                    </p>
                  </Alert>
                </Col>
              </Row>
              : evaluating ?
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Stack gap={4} direction="horizontal">
                    <Spinner animation="border" role="status" style={{color: "#fa7618"}}>
                      <span className="visually-hidden">Loading..</span>
                    </Spinner>
                    <p className='display-6 mt-3'>Loading...</p>
                  </Stack>
                </Col>
              </Row>
              :
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Stack gap={4} direction="vertical" className='mx-auto col-md-5'>
                    <Row className='d-flex justify-content-center'>
                        <Tooltip
                          id="metrics-tooltip"
                          place="bottom"
                          style={{borderRadius: '5px', textAlign: 'center', maxWidth: '500px'}}
                          render={({ activeAnchor }) => (
                            <span>
                              <span className='h6'><b>{activeAnchor?.getAttribute('name') || 'unknown'}</b></span>
                              <br/>
                              {activeAnchor?.getAttribute('description') || 'unknown'}
                            </span>
                          )}
                        />
                      <Table striped bordered style={{maxWidth: "900px"}} >
                        <thead>
                          <tr>
                            <th className="text-center align-middle">Engine</th>
                            <th className="text-center align-middle">ID</th>
                            <th className="text-center align-middle" 
                              data-tooltip-id='metrics-tooltip'
                              name="Bilingual Evaluation Understudy"
                              description="BLEU is a metric used to measure how well a machine-translated text matches human translations. It quantifies the similarity between the machine's output and one or more reference translations. The higher the BLEU score, the better the machine translation is considered to be."
                              >
                                <ArrowUp className='mb-1'/> BLEU
                            </th>
                            <th className="text-center align-middle" 
                              data-tooltip-id='metrics-tooltip'
                              name="Character n-gram F-score"
                              description="CHRF is an evaluation metric that assesses machine translation quality by comparing character-level n-grams between the machine's translation and reference translations. The higher the CHRF score, the better the machine translation is considered to be."
                              >
                                <ArrowUp className='mb-1'/> CHRF
                            </th>
                            <th className="text-center align-middle"
                              data-tooltip-id='metrics-tooltip'
                              name="Translation Edit Rate"
                              description="TER is a metric that measures the edit distance between a machine-generated translation and a reference translation. It quantifies the number of edits (insertions, deletions, substitutions) needed to make the machine translation match the reference. The lower the TER score, the better the machine translation is considered to be."
                              >
                                <ArrowDown className='mb-1'/> TER
                            </th>
                            <th className="text-center align-middle"><ArrowDown className='mb-1'/> Translation time</th>
                          </tr>
                        </thead>
                        <tbody>
                          { Object.entries(requestEngines).map(([engine,v]) =>
                          data.stats[engine] === undefined ?
                          <tr key={engine}>
                            <td className="text-center align-middle">{ !enginesName[engine] ? "UNKNOWN" : enginesService[engine] == 36 ? enginesName[engine] + "\t(CPU)" : enginesName[engine] }</td>
                            <td className="text-center align-middle">{engine}</td>
                            <td className="text-center align-middle" colSpan={4}>
                              <Stack gap={4} direction="horizontal" className='justify-content-center'>
                                <Spinner animation="border" size='sm' role="status" style={{color: "#fa7618"}}>
                                  <span className="visually-hidden">Evaluating...</span>
                                </Spinner>
                                <p className='my-0'>Evaluating...</p>
                              </Stack>
                            </td>
                          </tr>
                          : data.stats[engine].error ?
                          <tr key={engine}>
                            <td className="text-center align-middle">{ !enginesName[engine] ? "UNKNOWN" : enginesService[engine] == 36 ? enginesName[engine] + "\t(CPU)" : enginesName[engine] }</td>
                            <td className="text-center align-middle">{engine}</td>
                            <td className="text-center align-middle" colSpan={4}>
                              {data.stats[engine].error_msg}
                            </td>
                          </tr>
                          :
                          <tr key={engine}>
                            <td className="text-center align-middle">{ !enginesName[engine] ? "UNKNOWN" : enginesService[engine] == 36 ? enginesName[engine] + "\t(CPU)" : enginesName[engine] }</td>
                            <td className="text-center align-middle">{engine}</td>
                            <td className="text-center align-middle">{data.stats[engine]['bleu'].toFixed(2)}</td>
                            <td className="text-center align-middle">{data.stats[engine]['chrf'].toFixed(2)}</td>
                            <td className="text-center align-middle">{data.stats[engine]['ter'].toFixed(2)}</td>
                            <td className="text-center align-middle">{data.stats[engine]['time'] > 60 ? (data.stats[engine]['time']/60).toFixed(2) + "min" : data.stats[engine]['time'].toFixed(2) + "s"}</td>
                          </tr>
                          )
                          }
                        </tbody>
                      </Table>
                    </Row>
                  </Stack>
                </Col>
              </Row>
              }
              </Tab>
              <Tab eventKey="graphs" title="Evaluation graphs">
              { evaluatingError ?
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Alert 
                    variant='danger' 
                    show={evaluatingError} 
                    onClose={() => setEvaluatingError(false)} 
                    style={{
                      maxWidth: "600px",
                      minWidth: "350px",
                      top: "50px",
                      right: "50px",
                      position:"fixed",
                    }}>
                    <Alert.Heading>You got an {evaluatingErrorCode} error!</Alert.Heading>
                    <p>
                      {evaluatingErrorMsg}
                    </p>
                  </Alert>
                </Col>
              </Row>
              : evaluating ?
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Stack gap={4} direction="horizontal">
                    <Spinner animation="border" role="status" style={{color: "#fa7618"}}>
                      <span className="visually-hidden">Evaluating..</span>
                    </Spinner>
                    <p className='display-6 mt-3'>Evaluating...</p>
                  </Stack>
                </Col>
              </Row>
              :
              <>
                <Row>
                  <Col className='d-flex justify-content-center my-5'>
                    <Stack gap={4} direction="horizontal" className='mx-auto w-100'>
                      <ReactECharts option={BLEUData} className='w-100'/>
                      <ReactECharts option={CHRFData} className='w-100'/>
                      <ReactECharts option={TERData} className='w-100'/>
                    </Stack>
                  </Col>
                </Row> 
                </>
                }
              </Tab>
              <Tab eventKey="source" title="Source statistics">
              { evaluatingError ?
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Alert 
                    variant='danger' 
                    show={evaluatingError} 
                    onClose={() => setEvaluatingError(false)} 
                    style={{
                      maxWidth: "600px",
                      minWidth: "350px",
                      top: "50px",
                      right: "50px",
                      position:"fixed",
                    }}>
                    <Alert.Heading>You got an {evaluatingErrorCode} error!</Alert.Heading>
                    <p>
                      {evaluatingErrorMsg}
                    </p>
                  </Alert>
                </Col>
              </Row>
              : evaluating ?
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Stack gap={4} direction="horizontal">
                    <Spinner animation="border" role="status" style={{color: "#fa7618"}}>
                      <span className="visually-hidden">Evaluating..</span>
                    </Spinner>
                    <p className='display-6 mt-3'>Evaluating...</p>
                  </Stack>
                </Col>
              </Row>
              :
              <>
                <Row>
                  <Col className='d-flex justify-content-center my-5'>
                    <Stack gap={4} direction="vertical" className='mx-auto col-md-5'>
                      <Row className='d-flex justify-content-center'>
                        <Col className='d-flex justify-content-center'>
                          <Stack gap={4} direction="horizontal">
                            <p className='h5 mx-4'><b># words:</b> {data.dataset_words}</p>
                            <p className='h5 mx-4'><b>Vocabulary:</b> {data.dataset_vocab}</p>
                            <p className='h5 mx-4'><b>Avg. words/segment:</b> {data.dataset_avg_words.toFixed(2)}</p>
                          </Stack>
                        </Col>
                      </Row>
                      <Row className='d-flex justify-content-center'>
                        <Col className='d-flex justify-content-center'>
                          <Stack gap={2} direction="vertical" className='mx-auto col-md-5'>
                            <h5 className='h5' style={{textAlign:"center", fontWeight: "bold"}}>
                              Shortest segment (# words: {data.dataset_min_seg_words}, # chars: {data.dataset_min_seg_chars}):
                            </h5>
                            <p className='h5' dir={ lang_codes[data.src_lang] ? "rtl" : null } style={{textAlign:"center"}}>{data.dataset_min_seg}</p>
                          </Stack>
                        </Col>
                      </Row>
                      <Row className='d-flex justify-content-center'>
                        <Col className='d-flex justify-content-center'>
                          <Stack gap={2} direction="vertical" className='mx-auto col-md-5'>
                            <h5 className='h5' style={{textAlign:"center", fontWeight: "bold"}}>
                              Largest segment (# words: {data.dataset_max_seg_words}, # chars: {data.dataset_max_seg_chars}):
                            </h5>
                            <p className='h5' dir={ lang_codes[data.src_lang] ? "rtl" : null } style={{textAlign:"center"}}>{data.dataset_max_seg}</p>
                          </Stack>
                        </Col>
                      </Row>
                    </Stack>
                  </Col>
                </Row> 
                </>
                }
              </Tab>
              <Tab eventKey="settings" title="Request's settings" className='mb-5'>
                <Row>
                  <Col className='d-flex justify-content-center mt-5 mb-3'>
                    <p className='h4 mx-4'><b>Request name:</b> {data.name}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className='d-flex justify-content-center my-1'>
                    <Stack gap={5} direction="horizontal">
                      <p className='mt-3 h4 mx-4'><b>Dataset:</b> {data.dataset}</p>
                      <p className='mt-3 h4 mx-4'><b># segments:</b> {data.dataset_segments ? data.dataset_segments : data.num_segments == 0 ? "LOADING" : data.num_segments }</p>
                      { data.num_segments == datasets_length[data.dataset] ? null :
                      <p className='mt-3 h4 mx-4'><b>Random:</b> {data.random ? "True" : "False"}</p>
                      }
                    </Stack>
                  </Col>
                </Row>
                <Row>
                  <Col className='d-flex justify-content-center my-1'>
                    <Stack gap={5} direction="horizontal">
                      <p className='mt-3 h4 mx-4'><b>Batch size:</b> {data.batch_size}</p>
                      <p className='mt-3 h4 mx-4'><b>Timeout:</b> {data.timeout} min</p>
                    </Stack>
                  </Col>
                </Row>
                { data.dataset == "custom" ?
                <Row>
                  <Col className='d-flex justify-content-center my-1'>
                    <Stack gap={5} direction="horizontal">
                      <p className='mt-3 h5 mx-4'><b>Source file:</b> {data.src_file_name}</p>
                      <p className='mt-3 h5 mx-4'><b>Reference file:</b> {data.ref_file_name}</p>
                    </Stack>
                  </Col>
                </Row>
                : null }
              </Tab>
            </Tabs>
            </>
            }
          </Container>
          { !evaluating && !evaluatingError && showSegments ?
          <Container fluid className='px-5'>
              <Row>
                <Col className='d-flex justify-content-center my-5'>
                  <Stack gap={4} direction="vertical" className='mx-auto col-md-5'>
                    <h2 className='h2 mb-2' style={{textAlign:"center", fontWeight: "bold"}}>Segment analysis</h2>
                    <Row>
                      <Col className='d-flex justify-content-center'>
                          <Form.Check
                            type='switch'
                            checked={showDiff}
                            onChange={() => setShowDiff(!showDiff)}
                            label="Show differences"
                          />
                      </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th className="text-center">ID</th>
                            <th className={ lang_codes[data.src_lang] ? "text-end" : null }>Source</th>
                            <th className={ lang_codes[data.tgt_lang] ? "text-end" : null }>Reference</th>
                            { Object.entries(data.stats).map(([engine, values]) =>
                            data.stats[engine].error ? null :
                            <th key={engine} className={ lang_codes[data.tgt_lang] ? "text-end" : null }>{enginesName[engine]}</th>
                            )
                            }
                        </tr>
                        </thead>
                        <Tooltip
                          id="scores-tooltip"
                          place="bottom"
                          style={{borderRadius: '5px', textAlign: 'center'}}
                          render={({ activeAnchor }) => (
                            <span>
                              <span className='h6'><b>{activeAnchor?.getAttribute('engine') || 'unknown'}</b></span>
                              <br/>
                              <b><ArrowUp /> BLEU</b> = {activeAnchor?.getAttribute('bleu') || 'unknown'}
                              <br/>
                              <b><ArrowUp /> CHRF</b> = {activeAnchor?.getAttribute('chrf') || 'unknown'}
                              <br/>
                              <b><ArrowDown /> TER</b> = {activeAnchor?.getAttribute('ter') || 'unknown'}
                            </span>
                          )}
                        />
                        <tbody>
                          { Object.entries(data.seg_analysis).map(([id, values]) =>
                          <tr key={id}>
                            <td className="text-center">{id}</td>
                            <td dir={ lang_codes[data.src_lang] ? "rtl" : null } style={{wordBreak: "break-word"}}>{values.src}</td>
                            <td dir={ lang_codes[data.tgt_lang] ? "rtl" : null } style={{wordBreak: "break-word"}}>{values.ref}</td>
                            { Object.entries(data.stats).map(([engine, v]) =>
                            data.stats[engine].error ? null :
                            <td 
                              data-tooltip-id='scores-tooltip'
                              bleu={values.engines[engine].bleu.toFixed(2)}
                              ter={values.engines[engine].ter.toFixed(2)}
                              chrf={values.engines[engine].chrf.toFixed(2)}
                              engine={enginesName[engine]}
                              key={engine}
                              dir={ lang_codes[data.tgt_lang] ? "rtl" : null }
                              style={{wordBreak: "break-word"}}
                              >
                              { showDiff ?
                                ReactHtmlParser(diffline(values.engines[engine].mt,values.ref))
                              :
                                values.engines[engine].mt
                              }
                            </td>
                            )}
                          </tr>
                          )}
                        </tbody>
                      </Table>
                    </Row>
                  </Stack>
                </Col>
              </Row>
        </Container>
        : null }
      </div>

    );
}